import Header from "../../components/Header";

const Blog = () => {
  return (
    <>
      <Header />
    </>
  );
};

export default Blog;
