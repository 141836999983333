import { Container, TextBox, Title } from "./style";

const BlogBox = () => {
  return (
    <Container>
      <Title>Blog</Title>
      <TextBox>
        There are many variations of passages of Lorem Ipsum means available but
        suffered alteration
      </TextBox>
    </Container>
  );
};

export default BlogBox;
