import Header from "../../components/Header";

const NotFound = () => {
  return (
    <>
      <Header />
      Não encontrada
    </>
  );
};

export default NotFound;
