import Header from "../../components/Header";

const Profile = () => {
  return (
    <>
      <Header />
    </>
  );
};

export default Profile;
