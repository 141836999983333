import Header from "../../components/Header";
import MnlEditor from "../../components/MnlEditor";

const Plans = () => {
  return (
    <>
      <Header />
      <MnlEditor />
    </>
  );
};

export default Plans;
