import Header from "../../components/Header";

const Published = () => {
  return (
    <>
      <Header />
    </>
  );
};

export default Published;
